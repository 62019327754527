import React, { Component } from 'react';
import ReactDataSheet from 'react-datasheet';
import ReactToPrint from 'react-to-print';

import { IconButton, Icon, Grid, Tooltip } from '@material-ui/core';
import { $ } from 'moneysafe';
//import BestPrintDoc from './BestPrintDoc'
import './BestSection.scss';

class BestReactToPrint extends ReactToPrint{
  /*static defaultProps = {
    bestSectionTitle:"",
  }*/
  constructor(props) {
    super(props);
    this.state = {
      bestSectionTitle: '<h1 style="color:red;">something</h1>'
    }
  }
  /*render(){
    return (<div>this.state.bestTitle {super.render()} </div>);
  }*/
}


class BestSection extends Component {
  static defaultProps = {
    bestSectionTitle: 'Receipt',
    bestSectionColumns: [{name:'Title', format:'string'}, {name:'Item #',format:'string'}, {name:'Qty',},{name:'Unit Price',format:"money",},{name:'Ext. Price', format:"money", formula:{args:['Qty', 'Unit Price'], operation:'mul',}}],
    bestTotal:[{name:'Subtotal', operation:'sum', col:'last'}, {name:'Shipping Cost', value:0},
     {name:'Tax', operation:'mul', value:0.09, TotalRowNames:['Subtotal']},{name:'Total', operation:'sum-total'}],
    bestIsPrintMode: 0, 
  }
  
    constructor(props){
        super(props);
        this.state={
          bestGrid: [],
          rowNums:0,
          columnF:{},
          bestIsPrintMode: 0,
        }
        var titleRow = []
        console.log(JSON.stringify(this.state));

        this.props.bestSectionColumns.forEach((x, index) => {
          titleRow.push({value:x.name, readOnly:true});
          if (x.hasOwnProperty('formula')){
            var f = x.formula;
            f.args.forEach((x, index) => {
              if (typeof(x)==='string'){
                let numX = -1;
                let strX = x;
                this.props.bestSectionColumns.forEach((x, index) =>{
                  if (x.name === strX) { numX = index; return;}
                });
                f.args[index] = numX;
              }
            })
            this.state.columnF[index] = f;
          }
        });
        this.state.bestGrid.push(titleRow);
        
        //TODO read and fill dataRows
        //...
        //this.calculateExtPrices(bestGrid, row, col, 0);
        
        //Button "add row"
        var b = {
          value: '',
          component: (
            <IconButton variant="contained" size="small" onClick={() => {
              var newRow = [];
              this.props.bestSectionColumns.forEach(x=>{
                var newCell = {};
                if (x.hasOwnProperty('format')){
                  newCell['format'] = x.format;
                }
                if (x.format==="string"){
                  newCell['style'] = {textAlign:"left"};
                }
                newRow.push(newCell);
              });
              newRow[this.props.bestSectionColumns.length-1]['style']={textAlign:"right", backgroundColor:"unset"};
              newRow[this.props.bestSectionColumns.length-1]['readOnly']=true;
              //console.log('add');
              this.state.bestGrid.splice(1+this.state.rowNums,0,newRow);
              this.setState({rowNums:this.state.rowNums+1,});
              //console.log("AddRow:"+JSON.stringify(this.state));
            } }
            > <Icon color="secondary" fontSize="small">add_circle</Icon>
            </IconButton>
          ),
          forceComponent: true,
          colSpan: this.props.bestSectionColumns.length,
          style:{textAlign:"center", padding:"0.2rem"},
        }
        this.state.bestGrid.push([b]);
        //Total subsection
        this.props.bestTotal.forEach(row=>{
          this.state.bestGrid.push([
            {value: row.name +": ", readOnly: true, colSpan: this.props.bestSectionColumns.length - 1,style:{textAlign:"right",}},
            {
              value: row.value != null ?  $(row.value) + ' USD' : '0 USD',
              readOnly: row.operation ? true : false,
              style:{textAlign:"right", backgroundColor:"unset"},
              format:"money",
              //dataEditor: row.operation ? DataEditor: NumberEditor,
            }
          ]);
        });
        //Print Button
        this.state.bestSectionTitle = this.props.bestSectionTitle;
        this.calculateTotal(this.state.bestGrid, [ ...Array(this.props.bestTotal.length).keys() ].map( i => 1+this.state.rowNums+i+1));
    }
    calculateExtPrices(bestGrid, row, col, value){
      if (row == null) return;
      for (let key in this.state.columnF){
        let res = (this.state.columnF[key].operation==='mul') ? $(1) : $(0);
        bestGrid[row].forEach((cell, index) => {
          if (this.state.columnF[key].hasOwnProperty('args') && this.state.columnF[key].args.includes(index)){
            var val = $(parseFloat(cell.value));
            //console.log("val:" + val + " cell:" + JSON.stringify(cell));
            if (isNaN(val)){
              bestGrid[row][index].value = null;
            }/*
            else if (bestGrid[row][index].format === 'money'){
              //console.log("adding usd");
              bestGrid[row][index].value = $(val) + ' USD'; // add USD for currency
            }*/
            if (this.state.columnF[key].operation==='mul') res = $(0).add(res*val);
            else if (this.state.columnF[key].operation==='sum') res = res.add(val);
            //console.log ("after res: " + res);
            //TODO "else not above" than not supported 
          }
          else if(index===Number(key)) {
            //console.log ("res: " + res);
            //cell.value = res+' USD';
            if (isNaN(res)) bestGrid[row][index].value = "";
            else bestGrid[row][index].value = res +' USD';
          }
          else{
            //console.log("no action key = "+ key + "; index = " +index);
            //console.log(typeof(key) + " " +typeof(index) + " " + typeof(Number(key)));
          }
        })
      }
    }
    calculateTotal(bestGrid, gridRows){
      //console.log(JSON.stringify(gridRows));
      //console.log ("calculating total prices" + this.state.rowNums);
      var totalSum = $(0);
      //console.log("totalSum: " + totalSum);
      let totalRowIdx = -1;
      var val;
      this.props.bestTotal.forEach((row, index)=>{
        var gridRow = 1+this.state.rowNums+index+1;
        if (gridRows.includes(gridRow)){
          val = $(parseFloat(bestGrid[gridRow][1]['value']));
          if (isNaN(val)){
            bestGrid[gridRow][index].value = '0 USD';
          }
          bestGrid[gridRow][1]['value'] = val + ' USD';

        }
        if (row.operation === 'sum'){
          //Subtotal
          let sum = $(0);
          let colNum = Number.isInteger(row['col']) ? row['col'] : row['col'] === 'last' ? this.state.bestGrid[0].length - 1 : undefined/* error */;
          if (colNum == null) throw new Error("Cannot calculate subtotal for " + row['col']);
          for (var i=1; i <= this.state.rowNums; i++){
            val = $(parseFloat(this.state.bestGrid[i][colNum]['value']));
            if (isNaN(val)) continue;
            sum = sum.add(val);
          }
          bestGrid[1+this.state.rowNums+index+1][1]['value'] = sum + ' USD';
        } else if (row.operation === 'mul'){
          let sum = $(0);
          row.TotalRowNames.forEach(name =>{
            for(var i = this.state.rowNums + 1; i < this.state.bestGrid.length; i++){
                if (bestGrid[i][0].value === name + ": "){
                  sum += $(parseFloat(bestGrid[i][1].value));
                  break;
                }
            }
          })
          bestGrid[1+this.state.rowNums+index+1][1]['value'] = $(0).add($(sum)*$(parseFloat(row.value))) + ' USD';
        } else if (row.operation ==='sum-total') {
          totalRowIdx = index;
        }
        //console.log("bestGrid[1+this.state.rowNums+index+1][1]:" + JSON.stringify(bestGrid[1+this.state.rowNums+index+1][1]));
        if (row.operation !=='sum-total'){ //exclude total line
          totalSum = totalSum.add(parseFloat(bestGrid[1+this.state.rowNums+index+1][1]['value'])) || $(0);
        }
        //console.log("totalSum: " + totalSum);
        //{name:'Tax', operation:'mul', value:0, TotalRowNames:['Subtotal']}
      // Shipping
      // Tax
      // Total
      })
      if (totalRowIdx !== -1)
        bestGrid[1+this.state.rowNums+totalRowIdx+1][1]['value'] = totalSum + ' USD';
      //this.setState({bestGrid});
      //console.log("CalculateTotal:"+JSON.stringify(this.state.bestGrid[1+this.state.rowNums+totalRowIdx+1][1]));
    }

    render() {
      const renderPrintHeader = () => {
        
        if (this.state.bestIsPrintMode) {
          return <div class={"print-only"} align={"center"}><h1>{this.state.bestSectionTitle}</h1></div>;
        }
      }
            return (
              <div className={'best-grid-container'}>
                <Grid container justify="flex-end"> 
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <div><Tooltip title = "Print Quotation for current sheet"><IconButton variant="contained" size="small" onClick={() => {
                      } }>
                         <Icon color="secondary" fontSize="small">print_square</Icon></IconButton>
                      </Tooltip></div>
                     )}}
                  content={() => this.componentRef}
                  //documentTitle = {this.props.bestSectionTitle}
                  /*onBeforeGetContent = {() =>
                    
                    <h1>{this.state.bestSectionTitle}</h1>
                  }*/
                  /*onBeforeGetContent = {() => {

                  }*/
                  onBeforeGetContent = {() => {
                    return new Promise((resolve, reject) => {
                      this.setState({bestIsPrintMode: 1}, resolve);
                  });
                }}
                  onAfterPrint = {() => {
                    return new Promise((resolve, reject) => {
                      this.setState({bestIsPrintMode: 0}, resolve);
                  });
                  }}


                 />
                      {/*<Tooltip title = "Download PDF"><IconButton variant="contained" size="small" onClick={() => {
                      } }>
                         <Icon color="secondary" fontSize="small">picture_as_pdf</Icon></IconButton>
                      </Tooltip>
                      <Tooltip title = "Download Excel"><IconButton variant="contained" size="small" onClick={() => {
                      } }>
                         <Icon color="secondary" fontSize="small">Explicit</Icon></IconButton>
                    </Tooltip>*/}
              </Grid>
              
                {/*<BestPrintDoc  ref={el => (this.componentRef = el)}></BestPrintDoc>*/}
                <div ref={el => (this.componentRef = el)} class={"print-contaiter"}>
                {renderPrintHeader()}
                  <ReactDataSheet
                  data={this.state.bestGrid}
                  valueRenderer = {cell => cell.value}
                  onCellsChanged= {changes => {
                    const bestGrid = this.state.bestGrid.map(row => [...row]);
                    //console.log(JSON.stringify(changes));
                    changes.forEach(({ cell, row, col, value }) => {
                      //var val = $(parseFloat(value.toString()));
                      //console.log("OnChange:=>to string:"+ $(0).add(parseFloat(value)).toString());
                      const validated = cell.format === 'money' ? $(0).add(parseFloat(value)) + ' USD' : value;
                      bestGrid[row][col] = { ...bestGrid[row][col], value:validated };
                      //bestGrid[row][col] = { ...bestGrid[row][col], value };
                      if (row <= this.state.rowNums){
                        this.calculateExtPrices(bestGrid, row, col, value);
                        //console.log("after calculate bestGrid[row][last]:" + this.state.bestGrid[row][this.state.bestGrid[row].length-1]);
                      }
                      //this.setState({this.calculateTotal()});
                    });
                    //console.log("OnChange:=>"+JSON.stringify(changes));
                    this.calculateTotal(bestGrid, changes.map(({row}) => {return row;}));
                    this.setState({ bestGrid });
                    //console.log("OnChange:=>"+JSON.stringify(this.state));
                  }

                  }
                  onContextMenu = {(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
                  attributesRenderer={(cell) => {return {'style': cell.style} || {}}}
                  
                />
                </div>
              </div>
            );          
    }
}

export default BestSection;