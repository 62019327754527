import { createMuiTheme } from '@material-ui/core/styles';
//import purple from '@material-ui/core/colors/purple';
import { blue } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
//import green from '@material-ui/core/colors/green';

const BestTheme = createMuiTheme({
  palette: {
    primary:red,
    secondary:blue,
  },
  overrides: {
    MuiFormControlLabel: {
        label: {
            fontSize: '0.75rem',
            lineHeight:"1rem",
        }
    }
  }
});



export default BestTheme