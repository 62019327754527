import React from 'react'



import { IconButton, Icon, Paper, Tabs, Tab, Box, Grid, Tooltip } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import ReactToPrint from 'react-to-print';


import BestSection from './BestSection';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/*value === index &&*/ (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const BestMainContent = (props) => {
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
  <div>
    <br/>
    <Paper>
        <Grid container direction="row">
          <Grid item xs={11}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
            <Tab label=" Product / Materials" value="1"/>
            <Tab label="Jobs / Services" value="2" />
        </Tabs>
          </Grid>
            {/*<Grid item container xs={1} direction="row" justify="flex-end">
              <div style={{float:"right;"}}>
              <Tooltip title="Print Quotation for all sheets">
              <IconButton variant="contained" size="small" onClick={() => {
                } }
              > <Icon color="primary" fontSize="small">print_square</Icon>
              </IconButton></Tooltip>
              </div>
              </Grid>*/}
        </Grid>

        <TabPanel value={value} index="1">
          <BestSection bestSectionTitle={"Invoice"}/>
        </TabPanel>
        <TabPanel value={value} index = "2">
          <BestSection bestSectionTitle={"Quotation"} bestSectionColumns = {[{name:'#'}, {name:'Title'}, {name:'Price'},{name:'Qty'}, {name:'Unit'}, {name:'ext.Price',formula:{args:['Qty','Unit Price'], operation:'mul',}}]}/>
        </TabPanel>
    </Paper>
    <br/>
  </div>

  );
}
export default BestMainContent;