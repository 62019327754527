import React from 'react';
//material-ui
import { Grid } from '@material-ui/core';
//import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';


//import { right } from '@material-ui/system';
//best
import './best.css';
import BestHeader from './BestHeader';
import BestMainContent from './BestMainContent'
import BestFooter from './BestFooter'

function Best(props){
  return(
    <div>
      <Grid container direction='column' >
        <Grid item>
         <BestHeader name = {props.name} />
        </Grid>
        <Grid item container>
          <Grid item xs={false} sm={2}>
          </Grid>
          <Grid item xs={12} sm={8}>
              <BestMainContent/>
          </Grid>
          <Grid item xs={false} sm={2}>
          </Grid>

        </Grid>
        <Grid item>
         <BestFooter/>
        </Grid>
      </Grid>
    </div>
  );
}

/*    <Grid
      justify="space-between" // Add it here :)
      container 
      spacing={24}
    >
      <Grid item>
        <Typography type="title" color="inherit">
          Title
        </Typography>
      </Grid>

      <Grid item>
        <div>
          <HeartIcon />
          <Button raised color="accent">
            Login
          </Button>
        </div>
      </Grid>
    </Grid>
    
          <Toolbar>

      <Typography type="title" color="inherit" style={{ flex: 1 }}>
      
      </Typography>
          
      </Toolbar>
    
    
    */

export default Best;