import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

//import './best.css';

const BestHeader = (props) => {
  return(
  <AppBar position = "static" margin = '0.5rem'>
    <Toolbar>
      <Typography className='best-logo' variant="h4" style={{flex:1}} >{props.name}</Typography>
      <img src={'img/best-logo.png'} className={'best-logo'} alt={"Beta Estimate Logo"} tooltip={"Beta Estimate"}/>
    </Toolbar>
  </AppBar>
  );
}
export default BestHeader;


